import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetOrganizationsStatisticsParams,
  OrganizationStatisticsItem,
  useStatisticsApi,
} from '@api/statistics';
import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import { TableProps } from '@molecules/Table/definitions';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

import { OrganizationsTableProps } from './definitions';

const OrganizationsTable = ({ filters }: OrganizationsTableProps) => {
  const { t } = useTranslation();

  const { showOrganizationsExportsModal } = useModalsContext();

  const tableState = useTableState({
    defaultSorting: [
      {
        id: 'urgent',
        desc: true,
      },
    ],
  });

  const { getOrganizationsStatistics } = useStatisticsApi();

  const tableParams = useMemo<
    Pick<GetOrganizationsStatisticsParams, 'page' | 'perPage' | 'orderBy'>
  >(() => {
    const { pagination, sorting } = tableState;

    const params: GetOrganizationsStatisticsParams = {
      perPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
    };

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    return params;
  }, [tableState]);

  const queryParams = useMemo<
    Omit<GetOrganizationsStatisticsParams, 'page' | 'perPage' | 'orderBy'>
  >(() => {
    const params: Omit<
      GetOrganizationsStatisticsParams,
      'page' | 'perPage' | 'orderBy'
    > = {};

    if (filters?.organizationType) {
      params.organizationTypeId = Number(filters.organizationType);
    }

    if (filters?.organizationId) {
      params.organizationId = Number(filters.organizationId);
    }

    if (filters?.dates) {
      const [from, to] = filters.dates.split(';');
      params.from = from;
      params.to = to;
    }

    if (filters?.excludeCancelled) {
      params.excludeCancelled = filters?.excludeCancelled;
    }

    return params;
  }, [filters]);

  const fullQueryParams = useMemo(() => {
    return { ...tableParams, ...queryParams };
  }, [queryParams, tableParams]);

  const { data: organizationStatisticsData, isLoading } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS_STATISTICS, fullQueryParams],
    queryFn: () => getOrganizationsStatistics(fullQueryParams),
    placeholderData: keepPreviousData,
  });

  const tableData = useMemo(
    () => organizationStatisticsData?.data ?? [],
    [organizationStatisticsData],
  );

  const totalResults = useMemo(
    () => organizationStatisticsData?.meta.total ?? 0,
    [organizationStatisticsData],
  );

  const alertText = useMemo(() => {
    return (
      <div className="flex flex-row items-center gap-2">
        <IconSVG icon="alert-circle" size={18} />
        <Typography size="sm">
          {t('Statistics.organizations.table.alertText')}
        </Typography>
      </div>
    );
  }, [t]);

  const columns = useMemo<
    TableProps<OrganizationStatisticsItem>['columns']
  >(() => {
    const columnHelper = createColumnHelper<OrganizationStatisticsItem>();

    return [
      columnHelper.accessor('name', {
        header: t('Statistics.organizations.table.columns.name'),
        enableSorting: true,
      }),
      columnHelper.accessor('transited', {
        header: t('Statistics.organizations.table.columns.transited'),
        enableSorting: true,
      }),
      columnHelper.accessor('returnedAsSender', {
        header: t('Statistics.organizations.table.columns.returnedAsSender'),
        enableSorting: true,
      }),
      columnHelper.accessor('requestedAsSender', {
        header: t('Statistics.organizations.table.columns.requestedAsSender'),
        enableSorting: true,
      }),
      columnHelper.accessor('totalAsSender', {
        header: t('Statistics.organizations.table.columns.totalAsSender'),
        enableSorting: true,
      }),
      columnHelper.accessor('returnedAsReceiver', {
        header: t('Statistics.organizations.table.columns.returnedAsReceiver'),
        enableSorting: true,
      }),
      columnHelper.accessor('requestedAsReceiver', {
        header: t('Statistics.organizations.table.columns.requestedAsReceiver'),
        enableSorting: true,
      }),
      columnHelper.accessor('totalAsReceiver', {
        header: t('Statistics.organizations.table.columns.totalAsReceiver'),
        enableSorting: true,
      }),
      columnHelper.accessor('urgent', {
        header: t('Statistics.organizations.table.columns.urgent'),
        enableSorting: true,
      }),
      columnHelper.accessor('desiredDeliveryDate', {
        header: t('Statistics.organizations.table.columns.desiredDeliveryDate'),
        enableSorting: true,
      }),
      columnHelper.accessor('withReserve', {
        header: t('Statistics.organizations.table.columns.withReserve'),
        enableSorting: true,
      }),
      columnHelper.accessor('avgDelivery', {
        header: t('Statistics.organizations.table.columns.avgDelivery'),
        enableSorting: true,
        cell: (info) => {
          const value = info.getValue();
          return (
            <Typography size="sm">
              {value
                ? t('Statistics.organizations.table.values.avgDelivery', {
                    dayCount: info.getValue(),
                  })
                : ''}
            </Typography>
          );
        },
      }),
    ];
  }, [t]);

  return (
    <div className="w-full flex flex-col items-stretch gap-4">
      <AdminTableCard
        isLoading={isLoading}
        noDataMessage={t('Statistics.organizations.noData')}
        table={{
          data: tableData,
          columns,
          ...tableState,
          totalResults,
          paginationSideElement: alertText,
        }}
      />
      <Card>
        <div className="w-full flex flex-col md:flex-row md:justify-end md:items-center gap-3">
          <Button
            className="min-w-[220px]"
            type="primary"
            label={t('General.export')}
            onClick={() => showOrganizationsExportsModal({ queryParams })}
          />
        </div>
      </Card>
    </div>
  );
};

export default React.memo(OrganizationsTable);
