import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import {
  GetOrganizationListParams,
  GetOrganizationListResponse,
  OrganizationListItem,
} from '@api/OrganizationsApi/definitions';
import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import { useDownloadExcel } from '@hooks/useDownload.ts';
import useToast from '@hooks/useToast';
import { Toggle } from '@molecules/Form/Toggle';
import { ScrollableView } from '@molecules/ScrollableView';
import { TableProps } from '@molecules/Table/definitions';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { BaseLayout } from '@templates/BaseLayout';

const SEARCH_MINIMUM_CHARACTERS = 3;

const OrganizationsPage = () => {
  const { t } = useTranslation();

  const { showWarningToast } = useToast();

  const { showAddOrganizationModal, showConfirmationModal } =
    useModalsContext();

  const {
    getOrganizationList,
    updateOrganizationStatus,
    getOrganizationsExport,
  } = useOrganizationsApi();

  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters =
      apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const tableState = useTableState({
    defaultSorting: [
      {
        id: 'enabled',
        desc: true,
      },
      {
        id: 'name',
        desc: true,
      },
    ],
  });

  const queryParams = useMemo<GetOrganizationListParams>(() => {
    const { pagination, sorting, columnFilters } = tableState;

    const params: GetOrganizationListParams = {
      perPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
    };

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    if (columnFilters.length > 0) {
      const nameFilter = columnFilters.find((f) => f.id === 'name');
      if (nameFilter) {
        params.textFilter = nameFilter.value as string;
      }
    }

    return params;
  }, [tableState]);

  const { data: organizationListData } = useQuery({
    queryKey: [QueryKeys.ORGANIZATION_LIST, queryParams],
    queryFn: () => getOrganizationList(queryParams),
    placeholderData: keepPreviousData,
  });

  const {
    callDownload: callOrganizationsExport,
    isLoading: isExportingOrganizations,
  } = useDownloadExcel({
    promiseMethod: () => getOrganizationsExport(),
    fileName: 'organizations-export',
  });

  const tableData = useMemo(
    () => organizationListData?.data ?? [],
    [organizationListData],
  );

  const totalResults = useMemo(
    () => organizationListData?.meta.total ?? 0,
    [organizationListData],
  );

  const { mutate: callUpdateOrganizationStatus } = useMutation({
    mutationFn: updateOrganizationStatus,
    onMutate: async ({ id, enabled }) => {
      const queryKey = [QueryKeys.ORGANIZATION_LIST, queryParams];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData =
        queryClient.getQueryData<GetOrganizationListResponse>(queryKey);

      if (previousData) {
        queryClient.setQueryData<GetOrganizationListResponse>(queryKey, {
          ...previousData,
          data: previousData.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                enabled,
              };
            }
            return item;
          }),
        });
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<GetOrganizationListResponse>(
          [QueryKeys.ORGANIZATION_LIST, queryParams],
          context.previousData,
        );
      }

      showWarningToast(t('Modals.OrganizationStatusConfirmation.errorMessage'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORGANIZATION_LIST, queryParams],
      });
    },
  });

  const columns = useMemo<TableProps<OrganizationListItem>['columns']>(() => {
    const columnHelper = createColumnHelper<OrganizationListItem>();

    return [
      columnHelper.accessor('name', {
        header: t('OrganizationsPage.table.columns.name'),
        enableSorting: true,
      }),
      columnHelper.accessor('address', {
        header: t('OrganizationsPage.table.columns.address'),
        enableSorting: false,
      }),
      columnHelper.accessor('organizationType.name', {
        header: t('OrganizationsPage.table.columns.type'),
        enableSorting: false,
      }),
      columnHelper.accessor('enabled', {
        header: t('OrganizationsPage.table.columns.status'),
        enableSorting: true,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center">
              <Toggle
                checked={info.getValue()}
                onChange={(checked) =>
                  showConfirmationModal({
                    title: t('Modals.OrganizationStatusConfirmation.title'),
                    content: t('Modals.OrganizationStatusConfirmation.content'),
                    onConfirm: () => {
                      callUpdateOrganizationStatus({
                        id: Number(info.row.original.id),
                        enabled: checked,
                      });
                    },
                  })
                }
              />
            </div>
          );
        },
      }),
    ];
  }, [callUpdateOrganizationStatus, showConfirmationModal, t]);

  useEffect(() => {
    const { onColumnFiltersChange } = tableState;

    if (parsedApiSearchValue !== '') {
      onColumnFiltersChange((prevState) => {
        // All these checks are important to avoid infinite loop
        const currentFilter = prevState.find((f) => f.id === 'name');

        if (currentFilter === undefined) {
          return [
            ...prevState,
            {
              id: 'name',
              value: parsedApiSearchValue,
            },
          ];
        }

        if (currentFilter.value !== parsedApiSearchValue) {
          return prevState.map((filter) => {
            if (filter.id === 'name') {
              return {
                ...filter,
                value: parsedApiSearchValue,
              };
            }
            return { ...filter };
          });
        }

        return prevState;
      });

      return;
    }

    onColumnFiltersChange((prevState) => {
      // This check is important to avoid infinite loop
      return prevState.length > 0 ? [] : prevState;
    });

    return;
  }, [parsedApiSearchValue, tableState]);

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        {/* Page title */}
        <div className="sticky z-[2] flex justify-between top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('OrganizationsPage.title')}
          </Typography>
        </div>

        <ScrollableView
          className="px-4 pt-0 pb-4 flex-1 flex flex-col gap-2"
          treshold={400}
        >
          <AdminTableCard
            title={t('OrganizationsPage.table.title')}
            search={{
              name: 'search-organizations',
              value: searchValue,
              onChange: setSearchValue,
            }}
            table={{
              data: tableData,
              columns,
              ...tableState,
              totalResults,
            }}
            footerActions={[
              {
                label: t('OrganizationsPage.add'),
                onClick: showAddOrganizationModal,
              },
            ]}
          />
          <Card>
            <div className="w-full flex flex-col md:flex-row md:justify-end md:items-center gap-3">
              <Button
                className="min-w-[220px]"
                type="primary"
                label={t('General.export')}
                onClick={callOrganizationsExport}
                disabled={isExportingOrganizations}
                iconLeft="export"
              />
            </div>
          </Card>
        </ScrollableView>
      </div>
    </BaseLayout>
  );
};

export default React.memo(OrganizationsPage);
