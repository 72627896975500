import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';

import { useShipmentApi } from '@api/ShipmentApi';

import useToast from './useToast';

export const useDownloadShipmentPdf = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { getShipmentPdf } = useShipmentApi();

  async function downloadShipmentPdf(shipmentId: number) {
    setIsLoading(true);
    const data = await getShipmentPdf(shipmentId);
    setIsLoading(false);
    if (data) {
      try {
        const blob = new Blob([data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `shipment_${shipmentId}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error('Error processing PDF:', error);
      }
    } else {
      console.error('No PDF data available');
    }
  }

  return {
    isLoading,
    downloadShipmentPdf,
  };
};

export const useDownloadExcel = ({
  promiseMethod,
  fileName,
  onSuccess,
  onError,
}: {
  promiseMethod: () => Promise<Blob>;
  fileName: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { showWarningToast } = useToast();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const callDownload = useCallback(async () => {
    try {
      setIsLoading(true);
      const blob = await promiseMethod();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}-${Date.now()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      onSuccess?.();
    } catch (error) {
      console.error('Failed to download Excel file:', error);

      if (error instanceof AxiosError && error.response?.status === 404) {
        showWarningToast(t('General.noDataExport'));
        return;
      }

      showWarningToast(t('ErrorPage.somethingWrong'));

      onError?.();
    } finally {
      setIsLoading(false);
    }
  }, [fileName, onError, onSuccess, promiseMethod, showWarningToast, t]);

  return {
    callDownload,
    isLoading,
  };
};
