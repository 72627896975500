import React, { useMemo } from 'react';

import { ButtonProps } from '@atoms/Button/definitions';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';

const Button = ({
  label,
  type,
  onClick,
  disabled,
  disableUppercase = false,
  className,
  iconLeft,
  iconRight,
  iconSize,
}: ButtonProps) => {
  const buttonType = useMemo(() => {
    if (type) {
      return `btn-${type}`;
    }
    return '';
  }, [type]);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`ui-btn btn py-2 ${buttonType} ${className || ''}`.trim()}
    >
      {iconLeft && (
        <IconSVG
          icon={iconLeft}
          size={iconSize || 26}
          color={type === 'primary' ? 'white' : 'black'}
        />
      )}
      <Typography
        isUppercase={!disableUppercase}
        size="sm"
        sizeMd="md"
        isBold
        color="inherit"
      >
        {label}
      </Typography>
      {iconRight && (
        <IconSVG
          icon={iconRight}
          size={iconSize || 26}
          color={type === 'primary' ? 'white' : 'black'}
        />
      )}
    </button>
  );
};

export default React.memo(Button);
