import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useStatisticsApi } from '@api/statistics';
import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { useDownloadExcel } from '@hooks/useDownload.ts';
import { BaseModal } from '@organisms/Modals/BaseModal';

import { OrganizationsExportsModalProps } from './definitions';

const OrganizationsExportsModal = ({
  open,
  onClose,
  queryParams,
}: OrganizationsExportsModalProps) => {
  const { t } = useTranslation();

  const { getOrganizationsStatisticsExport } = useStatisticsApi();

  const {
    callDownload: callStatisticsExport,
    isLoading: isExportingStatistics,
  } = useDownloadExcel({
    promiseMethod: () => getOrganizationsStatisticsExport(queryParams),
    fileName: 'organizations-statistics-export',
    onSuccess: onClose,
    onError: onClose,
  });

  return (
    <BaseModal
      id="statisticsOrganizationsExportsModal"
      title={t('Modals.OrganizationsExports.title')}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={onClose}
    >
      <Typography size="md" color="text-Primary-02">
        <Trans
          i18nKey="Modals.OrganizationsExports.description"
          components={{
            underline: (
              <Typography
                as="span"
                size="md"
                color="text-Primary-02"
                className="underline"
              />
            ),
          }}
        />
      </Typography>
      <div className="flex flex-col gap-3 mt-4">
        <Button
          type="primary"
          label={t('Modals.OrganizationsExports.cta.organizations')}
          onClick={callStatisticsExport}
          disabled={isExportingStatistics}
          iconLeft="export"
        />
        <Button type="secondary" label={t('General.back')} onClick={onClose} />
      </div>
    </BaseModal>
  );
};

export default React.memo(OrganizationsExportsModal);
