import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useReasonForRequestApi } from '@api/NotificationSettings';
import { NotificationSettingsStatus } from '@api/NotificationSettings/definitions';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import { Toggle } from '@molecules/Form/Toggle';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { NotificationModalProps } from './definitions';

const NotificationModal: React.FC<NotificationModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { showConfirmationModal } = useModalsContext();

  const { isDriver, isAdmin } = useUserContext();

  const { getNotificationSettings, updateNotificationSettings } =
    useReasonForRequestApi();

  // API calls
  const { data: queryData } = useQuery({
    queryKey: [QueryKeys.NOTIFICATION_SETTINGS],
    queryFn: getNotificationSettings,
    enabled: open,
  });

  const { mutate: callUpdateNotificationSettings } = useMutation({
    mutationFn: updateNotificationSettings,
    onMutate: async ({ settingField, enabled }) => {
      const queryKey = [QueryKeys.NOTIFICATION_SETTINGS];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData = queryClient.getQueryData(queryKey);

      const [first, second] = settingField.split('_');
      const transformedSettingField = `${first}${second[0].toUpperCase()}${second.slice(1)}`;

      if (previousData) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          [transformedSettingField]: enabled,
        });
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          [QueryKeys.NOTIFICATION_SETTINGS],
          context.previousData,
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.NOTIFICATION_SETTINGS],
      });
    },
  });

  const notificationsDescription = useMemo(() => {
    if (isDriver) {
      return (
        <>
          <Typography sizeMd="md" className="mb-4">
            {t('Modals.NotificationSettings.driver.description.firstParagraph')}
          </Typography>
          <Typography sizeMd="md" className="mb-4">
            {t(
              'Modals.NotificationSettings.driver.description.secondParagraph',
            )}
          </Typography>
        </>
      );
    }
    if (isAdmin) {
      return (
        <>
          <Typography sizeMd="md" className="mb-4">
            {t('Modals.NotificationSettings.admin.description.firstParagraph')}
          </Typography>
          <Typography sizeMd="md" className="mb-4">
            {t('Modals.NotificationSettings.admin.description.secondParagraph')}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography sizeMd="md" className="mb-4">
          {t('Modals.NotificationSettings.user.description')}
        </Typography>
        <Typography sizeMd="md" className="mb-4 italic">
          {t('Modals.NotificationSettings.user.guide.title')} <br />
          {t('Modals.NotificationSettings.user.guide.sender')} <br />
          {t('Modals.NotificationSettings.user.guide.receiver')}
        </Typography>
      </>
    );
  }, [isAdmin, isDriver, t]);

  return (
    <BaseModal
      id="notificationModal"
      size="large"
      showCloseButton={true}
      open={open}
      onClose={onClose}
      header={{
        leftElement: <div />,
        centerElement: (
          <Typography isUppercase className="text-lg font-bold">
            {t('Modals.NotificationSettings.title')}
          </Typography>
        ),
        rightElement: <IconSVG icon="close" size={16} onClick={onClose} />,
      }}
    >
      <div className="p-6">
        {notificationsDescription}
        <div className="flex items-center gap-4 justify-end px-4 mb-4">
          {isDriver || isAdmin ? (
            <Typography sizeMd="md">
              {t('Modals.NotificationSettings.senderReceiver')}
            </Typography>
          ) : (
            <>
              <Typography sizeMd="md">
                {t('Modals.NotificationSettings.sender')}
              </Typography>
              <Typography sizeMd="md">
                {t('Modals.NotificationSettings.receiver')}
              </Typography>
            </>
          )}
        </div>
        {Object.keys(NotificationSettingsStatus).map((s, index, statuses) => {
          const status = s as keyof typeof NotificationSettingsStatus;
          const statusParsed = status.replace('_', '');
          const isSenderChecked = Boolean(
            queryData?.[`sender${statusParsed}` as keyof typeof queryData],
          );
          const isReceiverChecked = Boolean(
            queryData?.[`receiver${statusParsed}` as keyof typeof queryData],
          );

          const isLastStatus = index === statuses.length - 1;

          const driverStatuses: Array<keyof typeof NotificationSettingsStatus> =
            [
              NotificationSettingsStatus.Delivered,
              NotificationSettingsStatus.Moving,
              NotificationSettingsStatus.Arrived,
              NotificationSettingsStatus.In_Transit,
            ];

          if (isDriver && driverStatuses.includes(status)) {
            return (
              <div
                key={status}
                className={`flex items-center justify-between px-6 py-4
                  ${!isLastStatus ? 'border-b border-Primary-03' : ''}`}
              >
                <Typography size="md" isBold>
                  {t(
                    `Modals.NotificationSettings.statuses.${status}`,
                  ).toUpperCase()}
                </Typography>
                <div className="flex items-center space-x-12">
                  <Toggle
                    checked={isSenderChecked}
                    onChange={(checked) => {
                      showConfirmationModal({
                        title: t(
                          'Modals.NotificationSettingsConfirmation.title',
                        ),
                        content: t(
                          'Modals.NotificationSettingsConfirmation.content',
                        ),
                        onConfirm: () => {
                          const statusLowercased =
                            status.toLowerCase() as Lowercase<
                              keyof typeof NotificationSettingsStatus
                            >;

                          callUpdateNotificationSettings({
                            settingField: `sender_${statusLowercased}`,
                            enabled: checked,
                          });
                        },
                      });
                    }}
                  />
                </div>
              </div>
            );
          } else if (isAdmin) {
            return (
              <div
                key={status}
                className={`flex items-center justify-between px-6 py-4
                  ${!isLastStatus ? 'border-b border-Primary-03' : ''}`}
              >
                <Typography size="md" isBold>
                  {t(
                    `Modals.NotificationSettings.statuses.${status}`,
                  ).toUpperCase()}
                </Typography>
                <div className="flex items-center space-x-12">
                  <Toggle
                    checked={isSenderChecked}
                    onChange={(checked) => {
                      showConfirmationModal({
                        title: t(
                          'Modals.NotificationSettingsConfirmation.title',
                        ),
                        content: t(
                          'Modals.NotificationSettingsConfirmation.content',
                        ),
                        onConfirm: () => {
                          const statusLowercased =
                            status.toLowerCase() as Lowercase<
                              keyof typeof NotificationSettingsStatus
                            >;

                          callUpdateNotificationSettings({
                            settingField: `sender_${statusLowercased}`,
                            enabled: checked,
                          });
                        },
                      });
                    }}
                  />
                </div>
              </div>
            );
          } else if (!isDriver && !isAdmin) {
            return (
              <div
                key={status}
                className={`flex items-center justify-between px-6 py-4
                  ${!isLastStatus ? 'border-b border-Primary-03' : ''}`}
              >
                <Typography size="md" isBold>
                  {t(
                    `Modals.NotificationSettings.statuses.${status}`,
                  ).toUpperCase()}
                </Typography>
                <div className="flex items-center space-x-12">
                  <Toggle
                    checked={isSenderChecked}
                    onChange={(checked) => {
                      showConfirmationModal({
                        title: t(
                          'Modals.NotificationSettingsConfirmation.title',
                        ),
                        content: t(
                          'Modals.NotificationSettingsConfirmation.content',
                        ),
                        onConfirm: () => {
                          const statusLowercased =
                            status.toLowerCase() as Lowercase<
                              keyof typeof NotificationSettingsStatus
                            >;

                          callUpdateNotificationSettings({
                            settingField: `sender_${statusLowercased}`,
                            enabled: checked,
                          });
                        },
                      });
                    }}
                  />
                  <Toggle
                    checked={isReceiverChecked}
                    onChange={(checked) => {
                      showConfirmationModal({
                        title: t(
                          'Modals.NotificationSettingsConfirmation.title',
                        ),
                        content: t(
                          'Modals.NotificationSettingsConfirmation.content',
                        ),
                        onConfirm: () => {
                          const statusLowercased =
                            status.toLowerCase() as Lowercase<
                              keyof typeof NotificationSettingsStatus
                            >;

                          callUpdateNotificationSettings({
                            settingField: `receiver_${statusLowercased}`,
                            enabled: checked,
                          });
                        },
                      });
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    </BaseModal>
  );
};

export default React.memo(NotificationModal);
