import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { Shipment } from '@definitions/Shipment';
import { useLogger } from '@hooks/useLogger';

import {
  AcceptShipmentPayload,
  AcceptShipmentResponse,
  CheckShipmentPayload,
  CreateShipmentPayload,
  CreateShipmentResponse,
  GetShipmentByCodeResponse,
  GetShipmentPayload,
  GetShipmentsHistoryFilteredParams,
  GetShipmentsHistoryFilteredResponse,
  GetShipmentsHistoryParams,
  GetShipmentsHistoryResponse,
  GetShipmentsHomeResponse,
  GetShipmentsInCourseFilteredParams,
  GetShipmentsInCourseFilteredResponse,
  GetShipmentsInCourseParams,
  GetShipmentsInCourseResponse,
  GetShipmentsParams,
  GetShipmentsPendingResponse,
  GetShipmentsResponse,
  PickUpShipmentPayload,
  ReceiveShipmentPayload,
  RefuseShipmentPayload,
  UpdateShipmentDelayedCommentPayload,
  UpdateShipmentStatusPayload,
  VerifyQrcodePayload,
  VerifyQrcodeResponse,
} from './definitions';

export const useShipmentApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Create shipment
  const createShipment = useCallback(
    async (payload: CreateShipmentPayload) => {
      const formData = new FormData();
      formData.append('product', JSON.stringify(payload));

      try {
        const { data } = await privateApi.post<CreateShipmentResponse>(
          '/shipments',
          payload,
          {
            headers: {
              'Content-Type': 'multipart/form-data; charset=utf-8;',
            },
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/home
  const getShipmentsHome = useCallback(async () => {
    try {
      const { data } =
        await privateApi.get<GetShipmentsHomeResponse>('/shipments/home');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  //GET /shipments/in-course
  const getShipmentsInCourse = useCallback(
    async (params: GetShipmentsInCourseParams) => {
      try {
        const { data } = await privateApi.get<GetShipmentsInCourseResponse>(
          '/shipments/in-course',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/in-course-filter
  const getShipmentsInCourseFiltered = useCallback(
    async (params: GetShipmentsInCourseFilteredParams) => {
      try {
        const { data } =
          await privateApi.get<GetShipmentsInCourseFilteredResponse>(
            '/shipments/in-course-filter',
            {
              params,
            },
          );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/history-filter
  const getShipmentsHistoryFiltered = useCallback(
    async (params: GetShipmentsHistoryFilteredParams) => {
      try {
        const { data } =
          await privateApi.get<GetShipmentsHistoryFilteredResponse>(
            '/shipments/history-filter',
            {
              params,
            },
          );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/history
  const getShipmentsHistory = useCallback(
    async (params: GetShipmentsHistoryParams) => {
      try {
        const { data } = await privateApi.get<GetShipmentsHistoryResponse>(
          '/shipments/history',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/pending
  const getShipmentsPending = useCallback(async () => {
    try {
      const { data } =
        await privateApi.get<GetShipmentsPendingResponse>('/shipments/pending');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  //GET /shipments
  const getShipments = useCallback(
    async (params: GetShipmentsParams) => {
      try {
        const { data } = await privateApi.get<GetShipmentsResponse>(
          '/shipments',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/id
  const getShipment = useCallback(
    async ({ shipmentId }: GetShipmentPayload) => {
      try {
        const { data } = await privateApi.get<Shipment>(
          `/shipments/${shipmentId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //GET /shipments/{shipmentId}/pdf
  const getShipmentPdf = useCallback(
    async (shipmentID: number) => {
      try {
        const { data } = await privateApi.get(`/shipments/${shipmentID}/pdf`, {
          responseType: 'blob',
        });
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/status
  const updateShipmentStatus = useCallback(
    async ({ shipmentID, ...payloadRest }: UpdateShipmentStatusPayload) => {
      try {
        const { data } = await privateApi.patch(
          `/shipments/${shipmentID}/status`,
          payloadRest,
          {
            headers: {
              'Content-Type': 'multipart/form-data; charset=utf-8;',
            },
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/refuse
  const refuseShipment = useCallback(
    async ({ shipmentID }: RefuseShipmentPayload) => {
      try {
        const { data } = await privateApi.patch(
          `/shipments/${shipmentID}/refuse`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/check
  const checkShipment = useCallback(
    async ({ shipmentID, comment }: CheckShipmentPayload) => {
      try {
        const { data } = await privateApi.patch(
          `/shipments/${shipmentID}/check`,
          { comment: comment },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/receive
  const receiveShipment = useCallback(
    async ({ shipmentID, ...payloadRest }: ReceiveShipmentPayload) => {
      try {
        const { data } = await privateApi.patch(
          `/shipments/${shipmentID}/receive`,
          payloadRest,
          {
            headers: {
              'Content-Type': 'multipart/form-data; charset=utf-8;',
            },
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/pick-up
  const pickUpShipment = useCallback(
    async ({ shipmentID, ...payloadRest }: PickUpShipmentPayload) => {
      try {
        const { data } = await privateApi.patch(
          `/shipments/${shipmentID}/pick-up`,
          payloadRest,
          {
            headers: {
              'Content-Type': 'multipart/form-data; charset=utf-8;',
            },
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //PATCH /shipments/id/accept
  const acceptShipment = useCallback(
    async ({ shipmentID, ...payloadRest }: AcceptShipmentPayload) => {
      try {
        const { data } = await privateApi.patch<AcceptShipmentResponse>(
          `/shipments/${shipmentID}/accept`,
          payloadRest,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // PATCH /shipments/{id}/delayed-comment
  const updateShipmentDelayedComment = useCallback(
    async ({
      shipmentId,
      ...payloadRest
    }: UpdateShipmentDelayedCommentPayload) => {
      try {
        const { data } = await privateApi.patch<AcceptShipmentResponse>(
          `/shipments/${shipmentId}/delayed-comment`,
          payloadRest,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  //POST /shipments/verify-qrcode
  const verifyQrcode = useCallback(
    async (payload: VerifyQrcodePayload) => {
      try {
        const { data } = await privateApi.post<VerifyQrcodeResponse>(
          '/shipments/verify-qrcode',
          payload,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // GET /shipments/code/${shipmentCode}
  const getShipmentByCode = useCallback(
    async (shipmentCode: string) => {
      try {
        const { data } = await privateApi.get<GetShipmentByCodeResponse>(
          `/shipments/code/${shipmentCode}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // GET getDownloadAttachment
  const getDownloadAttachments = useCallback(
    async (shipmentId: string) => {
      try {
        const { data } = await privateApi.get<GetShipmentByCodeResponse>(
          `/shipments/${shipmentId}/status-history`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getShipmentByCode,
    getShipmentsHome,
    getShipmentsInCourse,
    getShipmentsInCourseFiltered,
    getShipmentsHistoryFiltered,
    getShipmentsHistory,
    getShipmentsPending,
    getShipments,
    getShipment,
    getShipmentPdf,
    createShipment,
    updateShipmentStatus,
    refuseShipment,
    checkShipment,
    receiveShipment,
    acceptShipment,
    updateShipmentDelayedComment,
    verifyQrcode,
    pickUpShipment,
    getDownloadAttachments,
  };
};
