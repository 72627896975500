import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  GetOrganizationsExportParams,
  GetOrganizationsStatisticsParams,
  GetOrganizationsStatisticsResponse,
  GetShipmentExportParams,
  GetShipmentStatisticsParams,
  GetShipmentStatisticsResponse,
} from './definitions';

export const useStatisticsApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  const getOrganizationsStatistics = useCallback(
    async (params?: GetOrganizationsStatisticsParams) => {
      try {
        const { data } =
          await privateApi.get<GetOrganizationsStatisticsResponse>(
            '/statistics/organizations',
            {
              params,
            },
          );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getShipmentStatistics = useCallback(
    async (params?: GetShipmentStatisticsParams) => {
      try {
        const { data } = await privateApi.get<GetShipmentStatisticsResponse>(
          '/statistics/shipments',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getShipmentsStatisticsExport = useCallback(
    async (params?: GetShipmentExportParams) => {
      try {
        const response = await privateApi.get<Blob>(
          '/download/statistics/shipments',
          {
            params,
            responseType: 'blob',
          },
        );
        return response.data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getShipmentsProductsExport = useCallback(
    async (params?: GetShipmentExportParams) => {
      try {
        const response = await privateApi.get<Blob>(
          '/export/shipment-products',
          {
            params,
            responseType: 'blob',
          },
        );
        return response.data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getShipmentsCommentsExport = useCallback(
    async (params?: GetShipmentExportParams) => {
      try {
        const response = await privateApi.get<Blob>(
          '/export/shipment-comments',
          {
            params,
            responseType: 'blob',
          },
        );
        return response.data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getOrganizationsStatisticsExport = useCallback(
    async (params?: GetOrganizationsExportParams) => {
      try {
        const response = await privateApi.get<Blob>(
          '/download/statistics/organizations',
          {
            params,
            responseType: 'blob',
          },
        );
        return response.data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  return {
    getOrganizationsStatistics,
    getShipmentStatistics,
    getShipmentsStatisticsExport,
    getShipmentsProductsExport,
    getShipmentsCommentsExport,
    getOrganizationsStatisticsExport,
  };
};
