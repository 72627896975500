import React, { useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { useUserApi } from '@api/UserApi';
import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { yupResolver } from '@hookform/resolvers/yup';
import useToast from '@hooks/useToast';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { MultiSelect } from '@molecules/MultiSelect';
import { BaseModal } from '@organisms/Modals/BaseModal';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { noop } from '@utils/noop';

import { AddUserModalProps, FormValues, Organizations } from './definitions';
import { useValidationSchema } from './validation';

const AddUserModal = ({ open, onClose }: AddUserModalProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { showSuccessToast, showWarningToast } = useToast();

  const validation = useValidationSchema();

  const { getOrganizationList } = useOrganizationsApi();

  const { createUser } = useUserApi();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(validation),
    defaultValues: {
      externalId: '',
      firstName: '',
      lastName: '',
      organizations: [],
      email: '',
    },
  });

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  const [selectedOrganizations, setSelectedOrganizations] =
    useState<Organizations>();
  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters = apiSearchValue.length >= 3;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const {
    data: organizationListData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    enabled: open,
    queryKey: [QueryKeys.ORGANIZATION_LIST, parsedApiSearchValue],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationList({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const { mutate: callCreateUser, isPending: isAddingUser } = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      showSuccessToast(t('Modals.AddUser.successMessage'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USER_LIST],
        exact: false,
      });
      handleClose();
    },
    onError: () => {
      showWarningToast(t('Modals.AddUser.errorMessage'));
    },
  });

  const flattenedOrganizationList = useMemo(() => {
    if (!organizationListData) {
      return [];
    }

    return organizationListData.pages.flatMap((page) => page.data);
  }, [organizationListData]);

  const organizationOptions = useMemo(() => {
    if (!flattenedOrganizationList) {
      return [];
    }

    return flattenedOrganizationList.map((item) => ({
      value: String(item.id),
      label: item.name,
    }));
  }, [flattenedOrganizationList]);

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    (values) => {
      callCreateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        organizationIds: values.organizations.map((item) => Number(item.value)),
        externalId: values.externalId,
      });
      setSelectedOrganizations([]);
    },
    [callCreateUser],
  );

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  const roleValue = useMemo(() => {
    const roles = new Set<string>();

    // Iterate over selectedOrganizations and add appropriate roles to the set
    selectedOrganizations?.forEach((item) => {
      switch (item.value) {
        case '1':
          roles.add('Administrator');
          roles.add('Driver');
          roles.add('General User');
          break;
        case '73':
          roles.add('Driver');
          break;
        default:
          roles.add('General User');
          break;
      }
    });

    // Convert the set to an array and join the roles into a comma-separated string
    return Array.from(roles).join(', ');
  }, [selectedOrganizations]);

  const SELECTABLE_ORGANIZATIONS_LIMIT: number = useMemo(() => {
    if (selectedOrganizations?.find((item) => item.value === '1')) {
      return 1;
    }
    return 3;
  }, [selectedOrganizations]);

  const organizationOnChangeHandler = useCallback(
    (values: Organizations, onChange: (arg: unknown) => void) => {
      // Filter the values to include only those with value '1'
      const filteredValues = (values as Organizations).filter(
        (item) => item.value === '1',
      );

      // Update the selected organizations state
      setSelectedOrganizations(
        filteredValues?.length > 0 ? filteredValues : (values as Organizations),
      );

      // Call the onChange function with the filtered values if any, otherwise with the original values
      onChange(filteredValues.length > 0 ? filteredValues : values);
    },
    [],
  );

  return (
    <BaseModal
      id="addUserModal"
      title={t('Modals.AddUser.title')}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-9 items-stretch">
        <div className="flex flex-col gap-6 items-stretch">
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.firstName.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.firstName.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.lastName.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.lastName.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="organizations"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={t('Modals.AddUser.fields.organization.label')}
                required={true}
              >
                <MultiSelect
                  setSearchValue={setSearchValue}
                  selectedValues={value}
                  onChange={(values) =>
                    organizationOnChangeHandler(
                      values as Organizations,
                      onChange,
                    )
                  }
                  options={organizationOptions}
                  disabled={isAddingUser}
                  handleBottomReached={handleBottomReached}
                  value={value.map((item) => String(item.value))}
                  maxSelectableOptions={SELECTABLE_ORGANIZATIONS_LIMIT}
                  placeholder={t(
                    'Modals.AddUser.fields.organization.placeholder',
                  )}
                />
                {selectedOrganizations?.find((item) => item.value === '1') && (
                  <div className="flex items-center gap-1 mt-2">
                    <IconSVG icon={'warning_circle'} size={16} />
                    <Typography size="xs" sizeMd="xs">
                      {t('Modals.AddUser.fields.organization.adminWarning')}
                    </Typography>
                  </div>
                )}
                {error?.message && (
                  <Typography
                    color="text-System-red"
                    size="xs"
                    sizeMd="sm"
                    className="mt-1"
                  >
                    {error.message}
                  </Typography>
                )}
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="externalId"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.externalId.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    'Modals.AddUser.fields.externalId.placeholder',
                  )}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <FormField
            label={t('Modals.AddUser.fields.role.label')}
            required={false}
            disabled
          >
            <Input
              value={roleValue}
              placeholder={t('Modals.AddUser.fields.role.placeholder')}
              disabled={true}
              onChange={noop}
            />
          </FormField>
          <Controller
            control={control}
            name="email"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.email.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.email.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
        </div>
        <Button
          type="primary"
          label={t('General.ok')}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </BaseModal>
  );
};

export default React.memo(AddUserModal);
