import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { useUserApi } from '@api/UserApi';
import {
  GetUserListParams,
  GetUserListResponse,
} from '@api/UserApi/definitions';
import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import { useDownloadExcel } from '@hooks/useDownload';
import useToast from '@hooks/useToast';
import { Toggle } from '@molecules/Form/Toggle';
import { ScrollableView } from '@molecules/ScrollableView';
import { TableProps } from '@molecules/Table/definitions';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { BaseLayout } from '@templates/BaseLayout';

import { UserRow } from './definitions';

const SEARCH_MINIMUM_CHARACTERS = 3;

const UsersPage = () => {
  const { t } = useTranslation();

  const { showWarningToast } = useToast();

  const { showAddUserModal, showConfirmationModal } = useModalsContext();

  const { getUserList, updateUserStatus, getUsersExport, getDriversExport } =
    useUserApi();

  const { getOrganizationTypeList } = useOrganizationsApi();

  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharacters =
      apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

    return hasMinimumCharacters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const tableState = useTableState({
    defaultSorting: [
      {
        id: 'enabled',
        desc: true,
      },
      {
        id: 'fullName',
        desc: true,
      },
    ],
  });

  const queryParams = useMemo<GetUserListParams>(() => {
    const { pagination, sorting, columnFilters } = tableState;

    const params: GetUserListParams = {
      perPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
    };

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    if (columnFilters.length > 0) {
      const nameFilter = columnFilters.find((f) => f.id === 'name');
      if (nameFilter) {
        params.textFilter = nameFilter.value as string;
      }
    }

    return params;
  }, [tableState]);

  const { data: userListData } = useQuery({
    queryKey: [QueryKeys.USER_LIST, queryParams],
    queryFn: () => getUserList(queryParams),
    placeholderData: keepPreviousData,
  });

  const { data: organizationTypesData } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS_TYPE_LIST],
    queryFn: () => getOrganizationTypeList(),
  });

  const { mutate: callUpdateUserStatus } = useMutation({
    mutationFn: updateUserStatus,
    onMutate: async ({ id, enabled }) => {
      const queryKey = [QueryKeys.USER_LIST, queryParams];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData =
        queryClient.getQueryData<GetUserListResponse>(queryKey);

      if (previousData) {
        queryClient.setQueryData<GetUserListResponse>(queryKey, {
          ...previousData,
          data: previousData.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                enabled,
              };
            }
            return item;
          }),
        });
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<GetUserListResponse>(
          [QueryKeys.USER_LIST, queryParams],
          context.previousData,
        );
      }

      showWarningToast(t('Modals.UserStatusConfirmation.errorMessage'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USER_LIST, queryParams],
      });
    },
  });

  const { callDownload: callUsersExport, isLoading: isExportingUsers } =
    useDownloadExcel({
      promiseMethod: () => getUsersExport(),
      fileName: 'users-export',
    });

  const { callDownload: callDriversExport, isLoading: isExportingDrivers } =
    useDownloadExcel({
      promiseMethod: () => getDriversExport(),
      fileName: 'drivers-export',
    });

  const tableData = useMemo<UserRow[]>(() => {
    if (!userListData) {
      return [];
    }

    return userListData.data.map((user) => {
      const organizationType = organizationTypesData?.find(
        (item) => item.id === user.organizations[0].organizationTypeId,
      );

      return {
        ...user,
        profileImage: organizationType ? organizationType.image : '',
        /* The new BE service will return an array of objects to properly handle address / names
            new line should be:
            {user.organizations.map(item => (<div key={item.name}>{item}</div>))}
            It is mandatory that it stays in a div to handle the line breaks between one another
            Also fix the types if needed
          */
        organizationNames: (
          <div>
            {user.organizations.map((item) => (
              <div key={item.id}>{item.name}</div>
            ))}
          </div>
        ),
        organizationAddresses: (
          <div>
            {user.organizations.map((item) => (
              <div key={item.id}>{item.address}</div>
            ))}
          </div>
        ),
        roleName: (
          <div>
            {user.roles.map((item) => (
              <div key={item.id}>{item.name}</div>
            ))}
          </div>
        ),
        firstPassword: (
          <div>
            {user.firstPassword ? (
              <div>{user.firstPassword}</div>
            ) : (
              <div>{'*'.repeat(8)}</div>
            )}
          </div>
        ),
        fullName: (
          <div>
            {user.firstName} {user.lastName}
          </div>
        ),
      };
    });
  }, [userListData, organizationTypesData]);

  const totalResults = useMemo(() => {
    return userListData?.meta.total ?? 0;
  }, [userListData]);

  const columns = useMemo<TableProps<UserRow>['columns']>(() => {
    const columnHelper = createColumnHelper<UserRow>();

    return [
      columnHelper.accessor('fullName', {
        header: t('UsersPage.table.columns.fullName'),
        enableSorting: true,
      }),
      columnHelper.accessor('organizationNames', {
        header: t('UsersPage.table.columns.organization'),
        enableSorting: false,
      }),
      columnHelper.accessor('organizationAddresses', {
        header: t('UsersPage.table.columns.address'),
        enableSorting: false,
      }),
      columnHelper.accessor('roleName', {
        header: t('UsersPage.table.columns.role'),
        enableSorting: false,
      }),
      columnHelper.accessor('email', {
        header: t('UsersPage.table.columns.corporateEmail'),
        enableSorting: false,
      }),
      columnHelper.accessor('firstPassword', {
        header: t('UsersPage.table.columns.password'),
        enableSorting: false,
      }),
      columnHelper.accessor('enabled', {
        header: t('UsersPage.table.columns.status'),
        enableSorting: true,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center">
              <Toggle
                checked={info.getValue()}
                onChange={(checked) => {
                  showConfirmationModal({
                    title: t('Modals.UserStatusConfirmation.title'),
                    content: t('Modals.UserStatusConfirmation.content'),
                    onConfirm: () => {
                      callUpdateUserStatus({
                        id: Number(info.row.original.id),
                        enabled: checked,
                      });
                    },
                  });
                }}
              />
            </div>
          );
        },
      }),
    ];
  }, [callUpdateUserStatus, showConfirmationModal, t]);

  useEffect(() => {
    const { onColumnFiltersChange } = tableState;

    if (parsedApiSearchValue !== '') {
      onColumnFiltersChange((prevState) => {
        // All these checks are important to avoid infinite loop
        const currentFilter = prevState.find((f) => f.id === 'name');

        if (currentFilter === undefined) {
          return [
            ...prevState,
            {
              id: 'name',
              value: parsedApiSearchValue,
            },
          ];
        }

        if (currentFilter.value !== parsedApiSearchValue) {
          return prevState.map((filter) => {
            if (filter.id === 'name') {
              return {
                ...filter,
                value: parsedApiSearchValue,
              };
            }
            return { ...filter };
          });
        }

        return prevState;
      });

      return;
    }

    onColumnFiltersChange((prevState) => {
      // This check is important to avoid infinite loop
      return prevState.length > 0 ? [] : prevState;
    });

    return;
  }, [parsedApiSearchValue, tableState]);

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        {/* Page title */}
        <div className="sticky z-[2] flex justify-between top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('UsersPage.title')}
          </Typography>
        </div>

        <ScrollableView
          className="px-4 pt-0 pb-4 flex-1 flex flex-col gap-2"
          treshold={400}
        >
          <AdminTableCard
            title={t('UsersPage.table.title')}
            search={{
              name: 'search-users',
              value: searchValue,
              onChange: setSearchValue,
            }}
            table={{
              data: tableData,
              columns,
              ...tableState,
              totalResults,
            }}
            footerActions={[
              {
                label: t('UsersPage.add'),
                onClick: showAddUserModal,
              },
            ]}
          />
          <Card>
            <div className="w-full flex flex-col md:flex-row md:justify-end md:items-center gap-3">
              <Button
                className="min-w-[220px]"
                type="primary"
                label={t('General.exportDrivers')}
                onClick={callDriversExport}
                disabled={isExportingDrivers}
                iconLeft="export"
              />
              <Button
                className="min-w-[220px]"
                type="primary"
                label={t('General.exportUsers')}
                onClick={callUsersExport}
                disabled={isExportingUsers}
                iconLeft="export"
              />
            </div>
          </Card>
        </ScrollableView>
      </div>
    </BaseLayout>
  );
};

export default React.memo(UsersPage);
