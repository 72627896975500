import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import { ScrollableView } from '@molecules/ScrollableView';
import Items from '@organisms/Items';
import { BaseLayout } from '@templates/BaseLayout';

const ItemsPage = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        {/* Page title */}
        <div className="sticky z-[2] flex justify-between top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('ItemsPage.title')}
          </Typography>
        </div>

        <ScrollableView
          className="px-4 pt-0 pb-4 flex-1 flex flex-col gap-2"
          treshold={400}
        >
          <Items.ProductCategories />
          <Items.Supports />
          <Items.Packagings />
          <Items.ReasonForRequest />
          <Items.BillableCenterCost />
        </ScrollableView>
      </div>
    </BaseLayout>
  );
};

export default React.memo(ItemsPage);
