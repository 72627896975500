import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useStatisticsApi } from '@api/statistics';
import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { useDownloadExcel } from '@hooks/useDownload.ts';
import { BaseModal } from '@organisms/Modals/BaseModal';

import { TimechartExportsModalProps } from './definitions';

const TimechartExportsModal = ({
  open,
  onClose,
  queryParams,
}: TimechartExportsModalProps) => {
  const { t } = useTranslation();

  const {
    getShipmentsStatisticsExport,
    getShipmentsProductsExport,
    getShipmentsCommentsExport,
  } = useStatisticsApi();

  const {
    callDownload: callStatisticsExport,
    isLoading: isExportingStatistics,
  } = useDownloadExcel({
    promiseMethod: () => getShipmentsStatisticsExport(queryParams),
    fileName: 'shipment-statistics-export',
    onSuccess: onClose,
    onError: onClose,
  });

  const { callDownload: callProductsExport, isLoading: isExportingProducts } =
    useDownloadExcel({
      promiseMethod: () => getShipmentsProductsExport(queryParams),
      fileName: 'shipment-products-export',
      onSuccess: onClose,
      onError: onClose,
    });

  const { callDownload: callCommentsExport, isLoading: isExportingComments } =
    useDownloadExcel({
      promiseMethod: () => getShipmentsCommentsExport(queryParams),
      fileName: 'shipment-comments-export',
      onSuccess: onClose,
      onError: onClose,
    });

  return (
    <BaseModal
      id="statisticsTimechartExportsModal"
      title={t('Modals.TimechartExports.title')}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={onClose}
    >
      <Typography size="md" color="text-Primary-02">
        <Trans
          i18nKey="Modals.TimechartExports.description"
          components={{
            underline: (
              <Typography
                as="span"
                size="md"
                color="text-Primary-02"
                className="underline"
              />
            ),
          }}
        />
      </Typography>
      <div className="flex flex-col gap-3 mt-4">
        <Button
          type="primary"
          label={t('Modals.TimechartExports.cta.statistics')}
          onClick={callStatisticsExport}
          disabled={isExportingStatistics}
          iconLeft="export"
        />
        <Button
          type="primary"
          label={t('Modals.TimechartExports.cta.products')}
          onClick={callProductsExport}
          disabled={isExportingProducts}
          iconLeft="export"
        />
        <Button
          type="primary"
          label={t('Modals.TimechartExports.cta.comments')}
          onClick={callCommentsExport}
          disabled={isExportingComments}
          iconLeft="export"
        />
        <Button type="secondary" label={t('General.back')} onClick={onClose} />
      </div>
    </BaseModal>
  );
};

export default React.memo(TimechartExportsModal);
