import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import ScrollableView from '@molecules/ScrollableView/ScrollableView';
import Statistics from '@organisms/Statistics';
import {
  FiltersData as OrganizationsFiltersData,
  OrganizationsFiltersProps,
} from '@organisms/Statistics/OrganizationsFilters';
import {
  FiltersData as TimechartFiltersData,
  TimechartFiltersProps,
} from '@organisms/Statistics/TimechartFilters';
import {
  ToolbarProps,
  TabKeys as ToolbarTabKeys,
} from '@organisms/Statistics/Toolbar';
import { BaseLayout } from '@templates/BaseLayout';

const StatisticsPage = () => {
  const { t } = useTranslation();

  const { isAdmin } = useUserContext();

  const [activeTab, setActiveTab] = useState<ToolbarTabKeys>('timechart');

  const [organizationsFilters, setOrganizationsFilters] =
    useState<OrganizationsFiltersData | null>(null);

  const [timechartFilters, setTimechartFilters] =
    useState<TimechartFiltersData | null>(null);

  const handleOrganizationsFilterChange = useCallback<
    OrganizationsFiltersProps['onFiltersChange']
  >((filters) => {
    setOrganizationsFilters(filters);
  }, []);

  const handleTimechartFilterChange = useCallback<
    TimechartFiltersProps['onFiltersChange']
  >((filters) => {
    setTimechartFilters(filters);
  }, []);

  const handleActiveTabChange = useCallback<ToolbarProps['onActiveTabChange']>(
    (tab) => {
      setActiveTab(tab);
      if (tab !== 'organizations') {
        setOrganizationsFilters(null);
      }

      if (tab !== 'timechart') {
        setTimechartFilters(null);
      }
    },
    [],
  );

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        {/* Page title */}
        <div className="sticky z-[2] flex justify-between top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('Statistics.title')}
          </Typography>
        </div>

        {/* Filters */}
        <div className="md:p-4 md:pt-0 hidden md:block ">
          <Statistics.Toolbar
            activeTab={activeTab}
            onActiveTabChange={handleActiveTabChange}
            filtersNode={
              <Fragment>
                {activeTab === 'timechart' && (
                  <Statistics.TimechartFilters
                    onFiltersChange={handleTimechartFilterChange}
                  />
                )}
                {activeTab === 'organizations' && (
                  <Statistics.OrganizationsFilters
                    onFiltersChange={handleOrganizationsFilterChange}
                  />
                )}
              </Fragment>
            }
          />
        </div>

        <ScrollableView
          className="px-4 pt-0 pb-4 flex-1 flex flex-col gap-2"
          treshold={400}
        >
          <>
            <div className="md:p-4 md:pt-0 block md:hidden ">
              <Statistics.Toolbar
                activeTab={activeTab}
                onActiveTabChange={handleActiveTabChange}
                filtersNode={
                  <Fragment>
                    {activeTab === 'timechart' && (
                      <Statistics.TimechartFilters
                        onFiltersChange={handleTimechartFilterChange}
                      />
                    )}
                    {activeTab === 'organizations' && (
                      <Statistics.OrganizationsFilters
                        onFiltersChange={handleOrganizationsFilterChange}
                      />
                    )}
                  </Fragment>
                }
              />
            </div>
            {activeTab === 'timechart' && (
              <Statistics.Timechart filters={timechartFilters} />
            )}
            {activeTab === 'organizations' &&
              (isAdmin ? (
                <Statistics.OrganizationsTable filters={organizationsFilters} />
              ) : (
                <Statistics.SingleOrganization filters={organizationsFilters} />
              ))}
          </>
        </ScrollableView>
      </div>
    </BaseLayout>
  );
};
export default StatisticsPage;
